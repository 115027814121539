<script setup>

  /**
   * 
   * Komponens: Modal ablak a pénzváltásra vonatkozó információkkal.
   * 
  */

  let loading = ref(false);

  // nyelvi beállítások
  // const { locale, locales } = useI18n();
  
  // pinia stores:
  import { useCurrencyModalStore } from '@/store/currencymodal';
  const currencyModalStore = useCurrencyModalStore();
  const currencymodalStatus = computed(() => currencyModalStore.currencymodal);

</script>

<template>
  <Transition>
    <!-- flex items-center px-4 justify-between focus:outline-none text-left -->
    <div
      v-show="currencymodalStatus === true"
      class="fixed top-0 left-0 w-screen h-screen bg-opacity-50 z-50 xs:px-2 sm:px-1"
      style="background-color: rgba(0, 0, 0, 0.5)"
    >
      <div
        class="xs:max-w-sm sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl mt-52 mx-auto bg-page-dark p-2 rounded-lg shadow-lg"
      >
        <!-- loader icon -->
        <div v-show="loading" class="mx-auto text-center">
          <UiLoadSpinner v-show="loading" />
        </div>
        <!-- added item info -->
        <div v-if="!loading !== null" class="flex justify-between items-center">
          <!-- modal title -->
          <h2 class="text-lg text-txt-light">
            {{ $t('Switch currency') }}
          </h2>
          <div>
            <!-- close modal -->
            <button
              type="button"
              class="w-8 h-8 p-2 bg-primary text-txt-light hover:shadow-sm items-center rounded-md font-sans font-light text-sm disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none"
              @click="currencyModalStore.closeCurrencyModal()"
            >
              <font-awesome-icon :icon="['fas', 'times']" />
            </button>
          </div>
        </div>
        <!-- modal content -->
        <div class="p-2 my-5 flex content-center">
          <div class="text-4xl m-2">
            <font-awesome-icon :icon="['fas', 'info-circle']" class="" />
          </div>
          <div class="m-2 font-sans font-light">
            {{ $t('We charge you in HUF') }}<br />
            <span class="text-sm"
              >{{
                $t(
                  'Prices shown in other currencies serve for informative purposes only'
                )
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
